
import { defineComponent, ref } from 'vue'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)
import Hero from '@/components/Hero.vue'
import SelectCustom from '@/components/SelectCustom.vue'
import Task from '@/components/Task.vue'
import { chrome } from '@/guides/endpoints/chrome'
import { list } from '@/guides/endpoints/todo'

export default defineComponent({
  name: 'Todo',
  components: {
    Hero,
    SelectCustom,
    Task,
  },
  setup() {
    const chromeData = ref()
    const data = ref()
    const root = ref()
    const loading = ref(true)

    chrome().then(r => {
      chromeData.value = r.data
      console.log('chrome', r.data)
    })

    const getTaskByMonth = (month: string) => {
      if (!data.value.content?.todos?.items) {
        return []
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return data.value.content.todos.items.filter((item: any) => {
        return item?.month === month
      })
    }

    const fetch = () => {
      list().then(r => {
        data.value = r.data
        loading.value = false
      })
    }

    fetch()

    const jumpTo = (month: string) => {
      gsap.to(window, {
        duration: 0.2,
        ease: 'power4.out',
        scrollTo: {
          y: `[data-month="${month}"]`,
          offsetY: 150,
        },
      })
    }

    return {
      chromeData,
      data,
      getTaskByMonth,
      jumpTo,
      root,
      loading,
    }
  },
})
