<template>
  <div class="todo" ref="root" v-if="chromeData">
    <Hero title="To Do" :picture="chromeData?.headers.todo">
      <SelectCustom
        @select="jumpTo"
        v-if="data?.content?.customSelect"
        :data="data?.content?.customSelect"
      />
    </Hero>

    <section
      v-for="month in data?.content?.customSelect?.options"
      :key="month.value"
      class="wrapper"
    >
      <h3 :data-month="month.value">{{ month.label }}</h3>

      <div class="list">
        <Task
          v-for="item in getTaskByMonth(month.value)"
          :data="item"
          :key="item.id"
        />
      </div>
    </section>

    <div
      class="empty wrapper"
      v-if="!loading && !data?.content?.todos?.items?.length > 0"
    >
      <h4 class="mb-xxs">Pas de tâche</h4>
      <p>
        Tu n'as pas encore de tâche à réaliser dans l'immédiat.
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)
import Hero from '@/components/Hero.vue'
import SelectCustom from '@/components/SelectCustom.vue'
import Task from '@/components/Task.vue'
import { chrome } from '@/guides/endpoints/chrome'
import { list } from '@/guides/endpoints/todo'

export default defineComponent({
  name: 'Todo',
  components: {
    Hero,
    SelectCustom,
    Task,
  },
  setup() {
    const chromeData = ref()
    const data = ref()
    const root = ref()
    const loading = ref(true)

    chrome().then(r => {
      chromeData.value = r.data
      console.log('chrome', r.data)
    })

    const getTaskByMonth = (month: string) => {
      if (!data.value.content?.todos?.items) {
        return []
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return data.value.content.todos.items.filter((item: any) => {
        return item?.month === month
      })
    }

    const fetch = () => {
      list().then(r => {
        data.value = r.data
        loading.value = false
      })
    }

    fetch()

    const jumpTo = (month: string) => {
      gsap.to(window, {
        duration: 0.2,
        ease: 'power4.out',
        scrollTo: {
          y: `[data-month="${month}"]`,
          offsetY: 150,
        },
      })
    }

    return {
      chromeData,
      data,
      getTaskByMonth,
      jumpTo,
      root,
      loading,
    }
  },
})
</script>

<style lang="scss" scoped>
section {
  max-width: 1200px;

  h3 {
    @extend .mb-xs;
  }
}

section + section {
  @extend .mt-l;
}

.list {
  display: grid;
  gap: 15px;

  @include mq($from: s, $until: m) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq($from: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq($from: xxxl) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.empty {
  text-align: center;

  h4 {
    color: $rock-blue;
  }

  p {
    max-width: 370px;
    font-size: 12px;
    line-height: 16px;
    margin: 0 auto;
  }
}
</style>
